<template>
  <transition appear enter-active-class="animated fadeIn">
    <div class="has-text-centered">
      <div class="level-item">
        <h1 class="title is-1 page-title">ABOUT US</h1>
      </div>
      <div class="box b-shdw-3"><p>Covered2go travel insurance is a family run business with over 100 years of
        combined insurance experience. Specialising in travel insurance, online technology with a passion for the
        delivery of the highest possible customer service.</p>
        <br>
        <br>

        <h5 class="title is-4"><strong>BEST TRAVEL INSURANCE PROVIDER – INSURANCE CHOICE AWARDS 2022</strong></h5>
        <p> Thanks to the excellent feedback and reviews from our customers, Covered2Go are proud to have won Best
          Travel Insurance Provider and Treating Customers Fairly Champion, at the Insurance Choice Awards, in 2022.
        </p>
        <br>
        <br>

        <h5 class="title is-4"><strong>WHICH? MAGAZINE SUPERIOR COVID COVER RATING 2021</strong>
        </h5>
        <p>Which? reviewed hundreds of policies this year, sorting them into categories based on the strength of
          their Covid cover, and we are delighted that both our Platinum and Gold Policies carry a weighting of
          ‘Superior’.</p>
        <br>
        <br>

        <h5 class="title is-4"><strong>WHICH? MAGAZINE BEST RATE POLICY FROM 2011 – 2020</strong></h5>
        <p>We are delighted to have been awarded a Which? Magazine Best Rate Policy since 2011. To view the Best
          Rate Policy tables for travel insurance online please click here. Which? only recognise insurers who have
          high-quality products and provide first class customer service. They select the best insurers based on 22
          separate criteria, including customer satisfaction statistics plus their own assessment of each company's
          policies and cover.</p>
        <br>
        <br>

        <h5 class="title is-4"><strong>"EXCELLENT" 4.6 TRUSTSCORE FROM OUR CUSTOMERS VIA TRUSTPILOT</strong>
        </h5>
        <p>We are committed to delivering the best possible service, products and prices to you. We don't want to
          just meet your expectations, we want to repeatedly exceed them. Your opinions matter to us and that's why
          we encourage all our customers to review their experience, whether you have bought a policy online, needed
          help with a medical enquiry or assistance making a claim.</p>
        <br>
        <br>

        <h5 class="title is-4"><strong>'BEST TRAVEL INSURANCE PROVIDER' – FINALIST INSURANCE CHOICE AWARDS
          2017</strong></h5>
        <p>Covered2go were honoured to be named a finalist in the 'Best travel insurance provider' category, in
          the Insurance Choice Awards 2017. Run by Smart Money People, the Insurance Choice Awards are voted-for by
          consumers, and help to find the most customer-centric insurers in the UK. They are fast becoming a go-to
          reference for consumers looking for great insurance products and service. Over two million UK consumers
          are expected to come across these awards in 2017.</p>
        <br>
        <br>

        <h5 class="title is-4"><strong>TAILOR YOUR POLICY WITH EASE TO SUIT YOUR NEEDS</strong></h5>
        <p>Working closely with our team of underwriters gives us a degree of flexibility on our products. We
          understand that not everybody meets the standard criteria offered online. If you have a non-standard
          holiday situation and require travel insurance, we are happy to discuss this with you.</p><br></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AboutUs",
}
</script>

<style lang="scss" scoped>
h5 {
  color: $c2g_orange;
  margin-bottom: 8px !important;
  text-align: center;
}

.level-item {
  padding: 20px 0 40px 0;
  text-align: center;
}
</style>